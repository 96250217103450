import React, { Fragment, useState } from 'react'
import compass from 'assets/images/dashboard/sidebar/compass.svg'
import booking from 'assets/images/dashboard/sidebar/booking.svg'
import bookmark from 'assets/images/dashboard/sidebar/bookmark.svg'
import gear from 'assets/images/dashboard/sidebar/gear.svg'
import profile from 'assets/images/dashboard/sidebar/airplane.svg'
import logout from 'assets/images/dashboard/sidebar/log-out.svg'
import SideNavigation from "data/sideNav.json"
import { googleLogout } from '@react-oauth/google';
import { Loader } from "components/Loaders"
import { Link, useNavigate } from 'react-router-dom'


function SideNav() {

  const navigate = useNavigate();

  const [currentUrl, setCurrentUrl] = useState('')
  const [loading, setLoading] = useState(false)

  const isActive = (sidenavUrl) => {
    if (window.location.pathname === sidenavUrl){
      return '-is-active'
    } else {
      return ''
    }
  }

  const signout = () => {
    setLoading(true)
    googleLogout();
    localStorage.removeItem('user');
    setTimeout(() => {
      navigate('/signup');
      window.location.reload();
    }, 1000);
  }

  return (
    <Fragment>
      {loading && <Loader/>}
      <div className="dashboard__sidebar dashboard -is-sidebar-close bg-white scroll-bar-1">

        <div className="sidebar -dashboard">

          {SideNavigation.map((item, index) => {
            const icon = item.image === 'icon1' ? booking : item.image === 'icon2' ? bookmark : item.image === 'icon3' ? profile : item.image === 'icon4' ? gear : logout
            return (
              <div key={index} className="sidebar__item">
                <div className={`sidebar__button ${isActive(item.link)} ${currentUrl === item.link && '-is-active'}`}>
                  <Link to={item.link} onClick={() => setCurrentUrl(item.link)} className="d-flex items-center text-14 lh-1 fw-500">
                    <img src={icon} alt="image" className="mr-15" />
                    {item.label}
                  </Link>
                </div>
              </div>
            )
          })}

          <div className="sidebar__item">
            <div className="sidebar__button">
              <Link onClick={signout} className="d-flex items-center text-14 lh-1 fw-500">
                <img src={logout} alt="image" className="mr-15" />
                Logout
              </Link>
            </div>
          </div>

        </div>


      </div>
    </Fragment>
  )
}

export default SideNav;