import DashboardLayout from 'layouts/Dashboard';
import WebsiteLayout from 'layouts/Website';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import DashboardRoutes from 'routes/DashboardRoutes';
import AppRoutes from 'routes/routes';
import NoRoutes from 'routes/NoRoutes';
import { HeaderProvider } from 'config/HeaderContext';
import API from 'config/Api';
import ReactGA from "react-ga4";

ReactGA.initialize(API.MEASUREMENT_ID);

const App = () => {

  const value = localStorage.getItem('user')

  return (
    <BrowserRouter>
      <HeaderProvider>
        <Routes>
          <Route path="/" element={<WebsiteLayout />}>
            {AppRoutes.map((route, index) => (
              <Route key={index} path={route.path} element={route.component} />
            ))}
          </Route>
          <Route path="*" element={<NoRoutes />} />
          <Route path="/" element={<DashboardLayout />}>
            {DashboardRoutes.map((route, index) => (
              <Route key={index} path={route.path} element={route.component} />
            ))}
          </Route>
        </Routes>
      </HeaderProvider>
    </BrowserRouter>
  );
}

export default App;
