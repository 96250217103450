const Events = {

  init: function() {
    const targets = document.querySelectorAll("[data-x-click]")
    if (!targets) return

    targets.forEach((eventTarget) => {
      const attributes = eventTarget.getAttribute('data-x-click').split(', ')

      attributes.forEach((el) => {
        const target = document.querySelector(`[data-x=${el}]`)

        eventTarget.addEventListener('click', () => {
          if (target) {
            const toggleClass = target.getAttribute('data-x-toggle')
            target.classList.toggle(toggleClass)
          }
        })
      })
    })
  },

  destroyHeaderMenu: function() {
    const headerMenu = document.querySelector('.header-menu');
    if (!headerMenu) return
    headerMenu.classList.remove('is-menu-active');
  }

};


export default Events;