import { lazy } from "react";

const Dashboard = lazy(() => import("pages/Dashboard/Dashboard"));
const Booking = lazy(() => import("pages/Dashboard/Booking"));
const BookingDetails = lazy(() => import("pages/Dashboard/Bookings/show"));
const WishList = lazy(() => import("pages/Dashboard/Wishlist"));
const EditProfile = lazy(() => import("pages/Dashboard/EditProfile"));
const Setting = lazy(() => import("pages/Dashboard/Setting"));


const DashboardRoutes = [
  {
    path: "/db-dashboard",
    component: <Dashboard />,
  },
  {
    path: "/db-booking",
    component: <Booking />,
  },
  {
    path: "/db-booking-details/:id",
    component: <BookingDetails />,
  }, 
  {
    path: "/db-wishlist",
    component: <WishList />,
  },
  {
    path: "/db-profile",
    component: <EditProfile />,
  },
  {
    path: "/db-settings",
    component: <Setting />,
  },
]

export default DashboardRoutes;