import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { LazyLoading } from 'components/Loaders';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from '../Website/Header';
import SideNav from './SideNav';

function DashboardLayout() {

  return (
    <Suspense fallback={<LazyLoading />}>

      {/* ========== Header ========== */}
      <Header />

      <div>
        {/* ========== SideNav ========== */}
        <SideNav />

        <ToastContainer/>

        {/* ========== Main ========== */}
        <Outlet />

      </div>

    </Suspense>
  )
}

export default DashboardLayout