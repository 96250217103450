import { lazy } from "react";

const NotFound = lazy(() => import("pages/404"));


const NoRoutes = () => {
  return (
    <NotFound />
  );
};

export default NoRoutes;