import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { LazyLoading } from 'components/Loaders';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from './Header';
import Footer from './Footer';

function WebsiteLayout() {

  return (
    <Suspense fallback={<LazyLoading />}>

      {/* ========== Header ========== */}
      <Header />

      {/* ========== Main ========== */}
      <main>
        <ToastContainer/>
        <Outlet />
      </main>

      {/* ========== Footer ========== */}
      <Footer />

    </Suspense>
  )
}

export default WebsiteLayout